import trackApiFetch from './track-api-fetch';
import vtRequestId from './vt-request-id';

const buildApiUrl = (path, host = process.env.NEXT_PUBLIC_VT_API_ENDPOINT) =>
  /^http/.test(path) ? path : `${host}${path}`;

export class ApiFetchError extends Error {
  body: string;

  constructor(message: string, public path: string, public response: Response, body: string) {
    super(message);
    this.response = response;
    this.path = path;
    this.body = body;
  }
}

const safeGetJson = (res: Response) => res.json().catch(() => null);

interface ApiFetchOptions extends RequestInit {
  host?: string;
  token?: string;
}

/**
 * A generic Fetcher for use with SWR that adapts to our API service.
 **/
export default function apiFetch<T = any>(
  path: string,
  { host, token, ...init }: ApiFetchOptions = {}
) {
  const url = buildApiUrl(path, host);
  return fetch(url, {
    ...init,
    // This line will automatically get browser auth and use it by default
    credentials: 'include',
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-vt-requestid': vtRequestId(),
      ...(init.headers || {}),
    },
    mode: 'cors',
  })
    .then(async (res) => {
      trackApiFetch('apiFetch', 'start', `${init.method || 'GET'} ${url}`);

      if (init.body) {
        trackApiFetch('apiFetch', 'body', `${vtRequestId()} ${init.body}`);
      }

      // we need to clone the response so that we can pass it into the error handler
      const body = await res.clone().text();
      const json = await safeGetJson(res);

      if (res.ok) {
        trackApiFetch(
          'apiFetch',
          'response_success',
          `${vtRequestId()} ${res?.status} ${res?.statusText} ${body}`
        );
        return json as T;
      }

      throw new ApiFetchError(json?.error || 'Error fetching data', path, res, body);
    })
    .catch((err: ApiFetchError) => {
      trackApiFetch(
        'apiFetch',
        'response_error',
        `${vtRequestId()} ${err.response?.status} ${JSON.stringify(err)} ${err.body} `
      );
      throw err;
    });
}

import { apiFetch } from '@nrdy-marketing-engine/api-fetch';
import { ApiFetchError } from '@nrdy-marketing-engine/api-fetch/src/api-fetch';
import { Me } from '@nrdy-marketing-engine/cart/@types/me';

import { Client } from '@nrdy-marketing-engine/cart/@types/cart-state';
import { CatalogItem } from '@nrdy-marketing-engine/cart/@types/catalog-item';
import { AvailableUserCreationTypes } from './@types/available-user-creation-types';

const FETCH_ME_PARAMS = 'include_entity=true&include_students=true&bypass_user_cache=true';

export class UserController {
  usersMeGet = () =>
    apiFetch<Me>(`/v1/users/me?${FETCH_ME_PARAMS}`).catch((err) => {
      if (err instanceof ApiFetchError && err.response.status.toString().startsWith('4')) {
        return null;
      }

      throw err;
    });

  usersMeConvert = () =>
    apiFetch('/v1/users/me/convert', {
      method: 'POST',
      body: JSON.stringify({
        cookies: {
          landing_page: location.href,
          page: location.href,
          referer: document.referrer || `${location.protocol}//${location.hostname}`,
        },
      }),
    }).catch((err) => {
      if (err instanceof ApiFetchError && err.response.status.toString().startsWith('4')) {
        return err;
      }

      throw err;
    });

  createAccount = (
    { email, password, firstName, lastName, lead_uuid }: Client,
    catalogItem: CatalogItem,
    type: AvailableUserCreationTypes = 'ecommerce_checkout'
  ) =>
    apiFetch('/v1/users', {
      body: JSON.stringify({
        additional_lead_data: {
          lead_form: {
            item_commodity_type: catalogItem?.commodity_type,
            item_id: catalogItem?.id,
            item_price: catalogItem?.price_cents,
          },
        },
        cookies: {
          landing_page: location.href,
          page: location.href,
          referer: document.referrer || `${location.protocol}//${location.hostname}`,
        },
        email,
        first_name: firstName,
        last_name: lastName,
        // lead_uuid,
        password,
        type,
      }),
      method: 'POST',
    });

  signIn = ({ email, password }: { email: string; password: string }) =>
    apiFetch<{ accessToken: string }>('/v1/users/login', {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        cookies: { page: location.href, referer: document.referrer, landing_page: location.href },
      }),
    }).catch((err) => {
      if (err instanceof ApiFetchError && err.response.status.toString().startsWith('4')) {
        throw new Error('User not found with this email and password');
      }
      throw new Error('Error occurred during login');
    });
}
